/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  INote,
  IEstimate,
  ArgsGetEstimates,
  IPhase,
} from '~/types/estimates';

const getEstimates = async (params: ArgsGetEstimates) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data.estimates;
};

const getStatsEstimates = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/stats`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.stats;
};

const getEstimatesList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const getEstimate = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.estimate;
};

const createEstimate = async (data: Partial<IEstimate>): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/estimates`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const updateEstimate = async (data: Partial<IEstimate>): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/estimates/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const createEstimatePhase = async (estimateId: string): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/phases`,
    {},
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const updateEstimatePhase = async (data: Partial<IPhase> & { estimateId : string })
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { _id, estimateId, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/phases/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const addEstimateNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { documentId, ...updatedData } = data;
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/estimates/${documentId}/notes`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const updateEstimateNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { documentId, _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/estimates/${documentId}/notes/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const deleteEstimateNote = async ({
  noteId, estimateId,
} : {
  noteId: string, estimateId: string
}) : Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/notes/${noteId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getEstimate,
  getEstimates,
  getStatsEstimates,
  getEstimatesList,
  createEstimate,
  updateEstimate,
  createEstimatePhase,
  updateEstimatePhase,
  addEstimateNote,
  updateEstimateNote,
  deleteEstimateNote,
};
