import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetEstimatesList } from '~/hooks/estimates';
import styles from './mobilization-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';

const DimensionForm = () => {
  const { control } = useFormContext();
  const { data: list = { mobilization: {} } } = useGetEstimatesList();
  const {
    mobilization,
  } = list.mobilization;

  return (
    <div className={styles.mobilization}>
      <h4>Montage/Démontage</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <StyledSelect
            label='Utilisation du ratio pour une structure en'
            name='mobilization.type'
            control={control}
            options={mobilization}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputNumber
            name='mobilization.assemblyTonnage'
            label='Tonnage à monter'
            control={control}
          />
        </div>
        <div className={styles.containerField}>
          <InputNumber
            name='mobilization.disassemblyTonnage'
            label='Tonnage à démonter'
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default DimensionForm;
