import React from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import styles from './back-button.module.scss';

const BackButton = ({ path } : { path?: string }) => {
  const navigate = useNavigate();
  return (
    <button className={`invisible ${styles.backButton}`} onClick={() => (path ? navigate(path) : navigate(-1))}>
      <IoArrowBack size={20} />
    </button>
  );
};

export default BackButton;
