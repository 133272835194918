import React, {
  useEffect, useCallback,
} from 'react';
import { LuLogOut } from 'react-icons/lu';
import { FieldValues, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { IUser } from '~/types/users';
import {
  useSignOut,
  useUserProfile,
  useUpdateProfile,
} from '~/hooks/auth';
import {
  InputText,
  InputFormatPattern,
  ErrorField,
} from '~/lib/HooksFormFields';
import Loader from '~/components/Loader';
// import QRCOde from '~/components/QRCode';
import styles from './account.module.scss';

const Account = () => {
  const { data } = useUserProfile();
  const user: Partial<IUser> = data || {};
  const {
    isLoading: isUpdateProfileLoading,
    mutate: updateProfile,
  } = useUpdateProfile('Les modifications ont été prise en compte');

  const signOut = useSignOut();
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      profile: {
        firstName: user?.profile?.firstName || '',
        lastName: user?.profile?.lastName || '',
      },
      phone: user?.phone || '',
    },
  });
  const errorsForm: any = errors;

  // function submit for update user
  const submit = useCallback(async (userData: FieldValues) => {
    updateProfile({
      ...userData,
    });
  }, [user]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <div className={styles.account}>
      {user && (
        <>
          <header>
            <div className={styles.content}>
              <h1>Mon compte</h1>
              {isUpdateProfileLoading && <div className={styles.loader} ><Loader small/></div>}
            </div>
          </header>
          <div className={styles.content}>
            {/* <QRCOde value={account?._id || ''} /> */}
            <div className={styles.form}>
              <div className={styles.headerBlock}>
                <h4>Mes informations</h4>
              </div>
              <section>
                <div className={styles.row}>
                  <div className={styles.containerField}>
                    <InputText
                      label="Prénom"
                      name='profile.firstName'
                      control={control}
                      rules={{
                        required: 'Ce champs est obligatoire',
                      }}
                    />
                    {errorsForm?.profile?.firstName?.message && (
                      <ErrorField message={errorsForm.profile.firstName.message} />
                    )}
                  </div>
                  <div className={styles.containerField}>
                    <InputText
                      label="Nom"
                      name='profile.lastName'
                      control={control}
                      rules={{
                        required: 'Ce champs est obligatoire',
                      }}
                    />
                    {errorsForm?.profile?.lastName?.message && (
                      <ErrorField message={errorsForm.profile.lastName.message} />
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.containerField}>
                    <InputFormatPattern
                      name="phone"
                      label="Téléphone"
                      control={control}
                      format="## ## ## ## ##"
                      rules={{
                        pattern: {
                          value: /^(?:\s*\d){10}$/,
                          message: "Le champs de numéro de téléphone n'est pas valide",
                        },
                      }}
                    />
                    {errorsForm?.phone?.message && (
                      <ErrorField message={errorsForm.phone.message} />
                    )}
                  </div>
                  <div className={styles.email}>
                    <label>Mail</label>
                    <p>{user.email}</p>
                  </div>
                </div>
              </section>
              <section className={styles.bottom}>
                <button className={`invisible ${styles.logout}`} onClick={signOut}>
                  <span>Me déconnecter</span>
                  <span><LuLogOut size={20} /></span>
                </button>
              </section>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Account;
