import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useGetAgencyPricing } from '~/hooks/agencies';
import styles from './pricing.module.scss';
import { IPricing, ITruck } from '~/types/agencies';
import PricingTable from '~/components/table/PricingTable';
// import Loader from '~/components/Loader';

type ITable = {
  columns: { header: string, accessorKey: string }[],
  rows: Record<string, any>[]
};

const Pricing = () => {
  const { id = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);
  const { data: pricing } = useGetAgencyPricing({
    id,
    ...paramsObject,
  });
  const [table, setTable] = useState<ITable>({
    columns: [],
    rows: [],
  });
  // const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    if (!pricing) return;
    const pricingRows: any[] = [];
    const columns: any = {
      location: { header: 'Villes', accessorKey: 'location' },
    };
    pricing.forEach((d: IPricing, index: number) => {
      const object: any = {
        _id: d._id,
        location: d.location,
        hourlyRate: d.hourlyRate,
        'originalAccessor-hourlyRate': `pricing.${index}.hourlyRate`,
      };
      d.trucks.forEach((truck: ITruck, i: number) => {
        object[truck.key] = truck.price;
        object[`originalAccessor-${truck.key}`] = `pricing.${index}.trucks.${i}.price`;
        if (!columns[truck.key]) {
          columns[truck.key] = {
            header: truck.label,
            accessorKey: truck.key,
          };
        }
      });
      pricingRows.push(object);
    });
    columns.hourlyRate = { header: 'Taux Horaire', accessorKey: 'hourlyRate' };
    setTable({
      columns: Object.values(columns),
      rows: pricingRows,
    });
    // setTimestamp(Date.now());
  }, [pricing]);

  const handleSorting = (sort: any) => {
    if (!sort) return;
    // console.log(sort);
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const defaultSorting = useMemo(() => {
    if (paramsObject.sortKey && paramsObject.order) {
      return { sortKey: paramsObject.sortKey, order: paramsObject.order };
    }
    return null;
  }, [paramsObject]);

  return (
    <div className={styles.pricing}>
      <div>
        {pricing && (
          <PricingTable
            // key={timestamp}
            agencyId={id}
            columns={table.columns}
            rows={table.rows}
            defaultPricing={(pricing as IPricing[])}
            defaultSorting={defaultSorting}
            handleSorting={handleSorting}
          />
        )}
      </div>
    </div>
  );
};

export default Pricing;
