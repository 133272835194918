import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  // Cell,
  // Row,
} from '@tanstack/react-table';
import styles from './pricing-table.module.scss';
import { IPricing } from '~/types/agencies';
import { useUpdateAgencyPricing } from '~/hooks/agencies';
import Tooltip from '~/lib/Tooltip';

import t7 from './images/t7.png';
import pl6m from './images/pl-6m.png';
import pl13m from './images/pl13-m.png';
import pl6mg from './images/pl6mg.png';
import pl12mg from './images/pl12mg.png';

// type IRowData = Record<string, any>;

const tooltipTruck: any = {
  'T7-max-12T': t7,
  'PL-6M': pl6m,
  'PL-13M': pl13m,
  'PL-6MG': pl6mg,
  'PL-12-MG': pl12mg,
};

const PricingTable = (
  {
    agencyId,
    columns,
    rows,
    defaultPricing,
    defaultSorting = null,
    handleSorting,
  } : {
    agencyId: string,
    columns: any[],
    rows: any[],
    defaultPricing: IPricing[],
    defaultSorting?: { sortKey: string, order: string } | null,
    handleSorting?: any,
  },
) => {
  const {
    mutate: updateAgencyPricing,
  } = useUpdateAgencyPricing('Le prix a été mise à jour');
  const { register, getValues } = useForm({
    defaultValues: {
      pricing: defaultPricing,
    },
  });
  const [sorting, setSorting] = useState<any>(defaultSorting ? [{
    id: defaultSorting.sortKey, desc: defaultSorting.order === 'desc',
  }] : []);

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0] || null);
  }, [sorting]);

  const data = useMemo(() => rows, [rows]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  // const onRowClick = (row: Row<IRowData>) => {
  //   console.log(row);
  // };

  const onBlur = (key: any, location: string) => {
    const value = getValues(key);
    updateAgencyPricing({
      _id: agencyId,
      key: key.replace(/pricing\.\d{1,}\./, ''),
      value,
      location,
    });
  };

  const renderInput = (cell: any) => {
    const formKey = cell.row.original[`originalAccessor-${cell.column.id}`];
    if (formKey) {
      const fieldRegister = register(formKey);
      const value = getValues(formKey) || '';
      return (
        <input
          {...fieldRegister}
          defaultValue={value}
          type='number'
          onBlur={(e) => {
            const { onBlur: fieldOnBlur } = fieldRegister;
            fieldOnBlur(e);
            onBlur(formKey, cell.row.original.location);
          }}
        />
      );
    }

    return null;
  };

  return (
    <div className={styles.pricingTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                 <p>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {header.id === 'location' && (
                    <>
                      <span className={`${styles.sortIcons} ${header.column.getIsSorted()
                        ? `${header.column.getIsSorted() === 'asc' ? styles.asc : styles.desc}`
                        : ''}`}>
                        <IoChevronUpOutline />
                        <IoChevronDownOutline />
                      </span>
                    </>
                  )}
                 </p>
                 {tooltipTruck[header.id] && (
                  <div className={styles.tooltip}>
                    <Tooltip>
                      <div className={styles.tooltipContent}>
                        <img src={tooltipTruck[header.id]} alt={header.id} />
                      </div>
                    </Tooltip>
                  </div>
                 )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              // onClick={() => onRowClick(row)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={cell.column.id !== 'location' ? styles.containerInput : ''}>
                  {cell.column.id !== 'location'
                    ? renderInput(cell)
                    : (
                      <p
                        className={styles[cell.column.id]}
                        title={cell.getValue() as string}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </p>
                    )
                  }
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PricingTable;
