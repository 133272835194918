import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useGetUsersList } from '~/hooks/users';
import styles from './users-table.module.scss';
import { IUser } from '~/types/users';
import { IOption } from '~/types/options';
import { IAgency } from '~/types/agencies';

const columns = [{
  header: 'Nom',
  accessorKey: 'profile.lastName',
}, {
  header: 'Prénom',
  accessorKey: 'profile.firstName',
}, {
  header: 'Mail',
  accessorKey: 'email',
}, {
  header: 'Agences',
  accessorKey: 'agencies',
  cell: (row: any) => {
    const agencies = row.getValue() || [];
    const strAgencies = (agencies || []).map((agency: Partial<IAgency>) => agency.name).join((', '));
    return strAgencies;
  },
}, {
  header: 'Poste',
  accessorKey: 'role',
  cell: (row: any) => {
    const value = row.getValue() || [];
    const { roles } = row;
    const optionRole = roles.find((d: IOption) => d.value === value);
    const color = optionRole?.color || '#6AA287';
    return (
      <div style={{ backgroundColor: color }} className={`${styles.role} ${styles[value]}`}>
        {optionRole?.label || value}
      </div>
    );
  },
}];

const UsersTable = (
  {
    users,
    handleSorting,
    baseTo = '/users',
  } : {
    users: IUser[],
    handleSorting?: any,
    baseTo?: string,
  },
) => {
  const { data: list = {} } = useGetUsersList();
  const { roles = [] } = list;
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0] || null);
  }, [sorting]);

  const data = useMemo(() => users, [users]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IUser>) => {
    navigate(`${baseTo}/${row.original._id}`);
  };

  return (
    <div className={styles.agenciesTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.id !== 'agencies' ? header.column.getToggleSortingHandler() : () => {}}
                >
                 <p>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {header.id !== 'agencies' && (
                    <>
                      {typeof header.column.getIsSorted() === 'string' ? (
                        {
                          asc: <IoChevronUpOutline />,
                          desc: <IoChevronDownOutline />,
                        }[header.column.getIsSorted() as string]
                      ) : (
                        <span className={styles.sortIcons}>
                          <IoChevronUpOutline />
                          <IoChevronDownOutline />
                        </span>
                      )}
                    </>
                  )}
                 </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div className={`${styles.cell} ${styles[cell.column.id]}`}>
                    <div className={styles.content}>
                      {cell.column.id === 'role' ? (
                        <>
                          {flexRender(
                            cell.column.columnDef.cell,
                            {
                              ...cell.getContext(),
                              roles,
                            },
                          )}
                        </>
                      ) : (
                        <>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </>
                      )}
                    </div>
                  </div>
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
