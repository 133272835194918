import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import iconUsers from '~/assets/images/icons/icon-users.svg';
import styles from './agencies-table.module.scss';
import { IAgency } from '~/types/agencies';

const columns = [{
  header: 'Nom',
  accessorKey: 'name',
}, {
  header: 'Location',
  accessorKey: 'location',
}, {
  header: 'Nombre de membres',
  accessorKey: 'usersCount',
}, {
  header: 'Nombre de devis en cours',
  accessorKey: 'estimates',
}, {
  header: 'Dernière mise à jour des tarifs',
  accessorKey: 'pricingUpdatedAt',
  cell: ({ getValue }: any) => {
    const value = getValue();
    return value ? formatDistanceToNow(value, { locale: fr }) : '___';
  },
}];

const AgenciesTable = (
  {
    agencies,
    handleSorting,
    baseTo = '/agencies',
  } : {
    agencies: IAgency[],
    handleSorting?: any,
    baseTo?: string,
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0] || null);
  }, [sorting]);

  const data = useMemo(() => agencies, [agencies]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IAgency>) => {
    navigate(`${baseTo}/${row.original._id}`);
  };

  return (
    <div className={styles.agenciesTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                 <p>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {typeof header.column.getIsSorted() === 'string' ? (
                    {
                      asc: <IoChevronUpOutline />,
                      desc: <IoChevronDownOutline />,
                    }[header.column.getIsSorted() as string]
                  ) : (
                    <span className={styles.sortIcons}>
                      <IoChevronUpOutline />
                      <IoChevronDownOutline />
                    </span>
                  )}
                 </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div className={`${styles.cell} ${styles[cell.column.id]}`}>
                    <div className={styles.content}>
                      {cell.column.id === 'usersCount' ? (
                        <>
                          <span>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </span>
                          <img src={iconUsers} />
                        </>
                      ) : flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </div>
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AgenciesTable;
