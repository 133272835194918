import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import styles from './clients-table.module.scss';
import { IClient } from '~/types/clients';

const columns = [{
  header: 'Nom de la société',
  accessorKey: 'name',
}, {
  header: 'Nom Prénom contact',
  accessorKey: 'contact.lastName',
  cell: ({ row: { original } }: any) => {
    const contact = original?.contact || {};
    return `${contact.firstName || '---'} ${contact.lastName || '---'}`;
  },
}, {
  header: 'Mail',
  accessorKey: 'contact.email',
}, {
  header: 'Nombre de devis associés',
  accessorKey: 'estimates',
}];

const ClientsTable = (
  {
    clients,
    handleSorting,
    baseTo = '/clients',
  } : {
    clients: IClient[],
    handleSorting?: any,
    baseTo?: string,
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0] || null);
  }, [sorting]);

  const data = useMemo(() => clients, [clients]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IClient>) => {
    navigate(`${baseTo}/${row.original._id}`);
  };

  return (
    <div className={styles.clientsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                 <p>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {typeof header.column.getIsSorted() === 'string' ? (
                    {
                      asc: <IoChevronUpOutline />,
                      desc: <IoChevronDownOutline />,
                    }[header.column.getIsSorted() as string]
                  ) : (
                    <span className={styles.sortIcons}>
                      <IoChevronUpOutline />
                      <IoChevronDownOutline />
                    </span>
                  )}
                 </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div className={`${styles.cell} ${styles[cell.column.id]}`}>
                    <div className={styles.content}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </div>
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsTable;
