import React, { useEffect } from 'react';
import styles from './phase-sidebar.module.scss';

const PhaseSidebar = () => {
  useEffect(() => {}, []);

  return (
    <div className={styles.sidebar}>
      <p>Menu Phase</p>
    </div>
  );
};

export default PhaseSidebar;
