import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { useUserProfile } from '~/hooks/auth';
import { useGetStatsEstimates } from '~/hooks/estimates';
import Modal from '~/lib/Modal';
import CreateEstimate from '~/components/estimate/form/CreateEstimate';
import styles from './home.module.scss';
import Loader from '~/components/Loader';

const Home = () => {
  const modalRef = useRef<any>();
  const { data: user } = useUserProfile();
  const navigate = useNavigate();
  const {
    isLoading: isStatsLoading,
    data: statsEstimates,
  } = useGetStatsEstimates();

  return (
    <>
      <Modal width={450} ref={modalRef}>
        <CreateEstimate onSuccess={(estimateId: string) => {
          modalRef.current.close();
          navigate(`/estimates/${estimateId}`);
        }} />
      </Modal>
        <div className={styles.home}>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>Bonjour {user ? user.profile.firstName : ''} !</h1>
              <button onClick={() => modalRef.current.open()}>
                <span className='icon'><IoMdAdd /></span>
                <span>Créer un devis</span>
              </button>
            </div>
            <div className={styles.stats}>
              <div>
                <h4>Statistiques</h4>
                <div className={styles.statsEstimates}>
                  {isStatsLoading && <div className={styles.loader}><Loader /></div>}
                  {statsEstimates && (
                    <>
                      <div className={styles.block}>
                        <p>Nb de devis total</p>
                        <p className={styles.count}>{statsEstimates.estimates}</p>
                      </div>
                      <div className={styles.block}>
                        <p>Nb de devis en cours</p>
                        <p className={styles.count}>{statsEstimates.estimatesInProgress}</p>
                      </div>
                      <div className={styles.block}>
                        <p>Nb de devis ce mois-ci</p>
                        <p className={styles.count}>{statsEstimates.estimatesThisMonth}</p>
                      </div>
                    </>
                  )}
                </div>
                <hr />
                <h4>Liste des rappels</h4>
              </div>
              <div></div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Home;
