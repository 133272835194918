import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { useGetClients, useGetClientsList } from '~/hooks/clients';
import InputSearch from '~/components/InputSearch';
import FilterSearchParam from '~/components/FilterSearchParam';
import styles from './clients.module.scss';
import ClientsTable from '~/components/table/ClientsTable';

const ListClients = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: list } = useGetClientsList();
  const { data: dataClients } = useGetClients({
    ...paramsObject,
    search: debouncedSearch,
  });
  const { clients } = dataClients || {};
  const { agencies = [], departments = [] } = list || {};

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.content}>
      <div className={styles.listClients}>
        <div className={styles.header}>
          <h1>Les Clients</h1>
          <div className={styles.filters}>
            <div className={styles.field}>
              <FilterSearchParam
                label='Agence'
                name='agency'
                options={agencies}
                feminine
              />
            </div>
            <div className={styles.field}>
              <FilterSearchParam
                label='Département'
                name='department'
                options={departments}
              />
            </div>
            <div className={styles.search}>
              <InputSearch
                defaultValue={search}
                handleSearch={handleSearch}
                label='Rechercher'
              />
            </div>
          </div>
        </div>
        <ClientsTable
          clients={clients || []}
          handleSorting={handleSorting}
        />
      </div>
    </div>
  );
};

export default ListClients;
