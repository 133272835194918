import React from 'react';
import EstimateNotesForm from '~/components/estimate/form/EstimateNotesForm';
import styles from './general-sidebar.module.scss';
import { IEstimate } from '~/types/estimates';

const GeneralSidebar = ({ estimate } : { estimate : IEstimate }) => (
  <div className={styles.sidebar}>
    <div className={styles.infos}>
      <p>
        Nom du client&nbsp;:&nbsp;
        <span>{estimate.client.name}</span>
      </p>
      <p>
        Commercial&nbsp;:&nbsp;
        <span>{estimate.author.profile?.firstName} {estimate.author.profile?.lastName}</span>
      </p>
    </div>
    <hr />
    <div className={styles.notes}>
      <EstimateNotesForm
        estimateId={estimate._id}
        notes={estimate.notes}
      />
    </div>
  </div>
);

export default GeneralSidebar;
