import React from 'react';
import Navbar from '../Navbar';
import styles from './layout.module.scss';

const Layout = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.layout}>
    <Navbar />
    <div className={styles.global}>
      {children}
    </div>
  </div>
);

export default Layout;
