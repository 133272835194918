/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getUser, getUsers, getUsersList, createUser, updateUser, deleteUser, updateUserPicture, updateUserDocuments,
} from '~/api/users';
import {
  IUser,
  ArgsGetUsers,
} from '~/types/users';

const useGetUsers = (params: ArgsGetUsers) => useQuery({
  queryKey: ['users', params],
  queryFn: async (): Promise<IUser[] | null> => getUsers(params),
  keepPreviousData: true,
});

const useGetUser = (id: string) => useQuery({
  queryKey: ['user', id],
  queryFn: async (): Promise<IUser | null> => getUser(id),
  keepPreviousData: true,
});

const useGetUsersList = (params?: { agencies: string[] } | null) => useQuery({
  queryKey: ['users-list', params],
  queryFn: async (): Promise<any | null> => getUsersList(params),
  keepPreviousData: true,
});

const useCreateUser = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IUser>) => createUser(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['agency-users'],
        exact: false,
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateUser = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IUser>) => updateUser(data),
    onSuccess: async ({ user }) => {
      queryClient.invalidateQueries({
        queryKey: ['agency-users'],
        exact: false,
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.setQueryData(['user', user._id], user);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateUserPicture = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { _id: string, picture: Blob | null }) => updateUserPicture(data),
    onSuccess: async ({ user }) => {
      queryClient.setQueryData(['user', user._id], user);
    },
  });
};

const useUpdateUserDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { _id: string, documents: FormData }) => updateUserDocuments(data),
    onSuccess: async ({ user }) => {
      queryClient.setQueryData(['user', user._id], user);
    },
  });
};

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteUser(id),
    onSuccess: async (_: any, variables: any) => {
      queryClient.invalidateQueries({
        queryKey: ['agency-users'],
        exact: false,
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.setQueryData(['user', variables], null);
    },
  });
};

export {
  useGetUsers,
  useGetUser,
  useGetUsersList,
  useCreateUser,
  useUpdateUser,
  useUpdateUserPicture,
  useUpdateUserDocuments,
  useDeleteUser,
};
