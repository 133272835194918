import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAgencies } from '~/hooks/agencies';
import styles from './agencies.module.scss';
// import Loader from '~/components/Loader';
import AgenciesTable from '~/components/table/AgenciesTable';

const Agencies = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);
  const { data } = useGetAgencies(paramsObject);
  const agencies = data?.agencies || [];

  const handleSorting = (sort: any) => {
    if (!sort) return;
    // console.log(sort);
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.agencies}>
      <div className={styles.content}>
        <h1>Les agences</h1>
        {/* {(!agencies && isLoading) && <Loader />} */}
        <AgenciesTable
          agencies={agencies}
          handleSorting={handleSorting}
        />
      </div>
    </div>
  );
};

export default Agencies;
