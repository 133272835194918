import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import styles from './detail.module.scss';
import { useDeleteUser, useGetUser, useGetUsersList } from '~/hooks/users';
import withModalConfirm from '~/lib/withModalConfirm';
import UserForm from '~/components/form/UserForm';
import BackButton from '~/components/BackButton';

const DetailUser = ({ confirm } : {
  confirm: any
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: user } = useGetUser(id || '');
  const agency = searchParams.get('agency') || user?.agencies[0]?._id;
  const { data: list = [] } = useGetUsersList(agency ? { agencies: [agency] } : null);
  // Delete
  const {
    mutate: deleteUser,
    isSuccess: isSuccessDelete,
    // error: deleteError,
    // isLoading: isDeleteLoading,
  } = useDeleteUser();

  const handleDeleteUser = useCallback(() => {
    if (!user) return;
    confirm(
      'Êtes-vour certain de vouloir supprimer ce membre ?',
      () => {
        deleteUser(user._id);
      },
    );
  }, [user?._id]);

  useEffect(() => {
    if (isSuccessDelete) navigate('/users');
  }, [isSuccessDelete]);

  return (
    <div className={styles.detailUser}>
      <div className={styles.header}>
        <div className={styles.content}>
          <div className={styles.titlePage}>
            <BackButton />
            <h1>{user?.profile.firstName} {user?.profile.lastName}</h1>
          </div>
          <button className={styles.delete} onClick={handleDeleteUser}>
            <span className='icon'><MdDelete /></span>
            <span>Supprimer le membre</span>
          </button>
        </div>
      </div>
      <div className={styles.content}>
        {(user && list?.roles) && (
          <UserForm
            user={user}
            list={list}
          />
        )}
      </div>
    </div>
  );
};

export default withModalConfirm(DetailUser);
