/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  Ref, useRef,
  useEffect, useMemo, useImperativeHandle, forwardRef, useCallback,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { IOption } from '~/types/options';
import {
  InputText,
  InputFormatPattern,
  StyledSelect,
  ErrorField,
  InputEmail,
} from '~/lib/HooksFormFields';
import DropInputFile from './DropInputFile';
import SaleForm from './SaleForm';
import { IUser } from '~/types/users';
import styles from './user-form.module.scss';
import {
  useUpdateUserPicture, useCreateUser, useUpdateUser,
} from '~/hooks/users';
import { useUserProfile } from '~/hooks/auth';
import MemberForm from './MemberForm';
import { IAgency } from '~/types/agencies';

interface userFormHandler {
  getData: () => void,
}

const UserForm = (
  { user, agency, list } : { user?: IUser | null, agency?: string | null, list: any },
  ref: Ref<userFormHandler>,
) => {
  type MemberFormHandle = React.ElementRef<typeof MemberForm>;
  const memberFormRef = useRef<MemberFormHandle>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: authUser } = useUserProfile();
  // const [searchParams, setSearchParams] = useSearchParams();
  // Create
  const {
    error: postError,
    // isLoading: isPostLoading,
  } = useCreateUser('Le nouvel utilisateur crée !');
  // Update
  const {
    mutate: updateUser,
    error: putError,
    // isLoading: isPutLoading,
  } = useUpdateUser('Les modifications ont été prise en compte');
  // Update picture
  const {
    mutate: updateUserPicture,
    error: putPictureError,
    // isLoading: isPutLoading,
  } = useUpdateUserPicture();

  // const isLoading: any = isPostLoading || isPutLoading;
  const errorApi: any = postError || putError || putPictureError;

  const {
    agencies = [],
    roles = [],
  } = list;

  let defaultValues: any = {
    role: !agency ? roles[0].value : 'Member',
  };
  if (user) {
    defaultValues = {
      ...user,
    };
    if (user.agencies.length > 0) {
      defaultValues.agencies = user.agencies
        .map((userAgency: Partial<IAgency>) => userAgency._id);
    }

    if (user.referrer1) {
      defaultValues.referrer1 = user.referrer1._id;
    }
    if (user.referrer2) {
      defaultValues.referrer2 = user.referrer2._id;
    }
  }

  if (agency) defaultValues.agencies = [agency];

  const {
    control,
    watch,
    trigger,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // mode: 'onChange',
    defaultValues,
  });

  const watchRole = watch('role');

  const {
    control: controlPicture,
    getValues: getValuesPicture,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      picture: user?.picture || null,
    },
  });

  // function submit for update user
  const submit = useCallback(async (data: FieldValues) => {
    if (!user) return;
    const dataUser: FieldValues = {
      _id: user._id,
      ...data,
    };
    // dataUser.agencies = (data.agencies || []).map((d: IOption) => d.value);
    await updateUser(dataUser);
  }, []);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const isValid = await trigger();
      let data: any = null;
      if (isValid) {
        const picture = getValuesPicture('picture') || null;

        data = getValues();
        // data.agencies = (data.agencies || []).map((d: IOption) => d.value);
        data.picture = picture;
      }

      const documents = memberFormRef.current?.getFormDataDocuments();

      return {
        userData: data,
        documents,
      };
    },
  }), [memberFormRef.current]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  // side effect on debouncedSubmit to trigger submit on change for existing user
  useEffect(() => {
    if (!debouncedSubmit || !user) return () => {};
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [debouncedSubmit, submitOnChange, user]);

  const handlePictureChange = (file: any) => {
    if (!user) return;
    updateUserPicture({
      _id: user._id,
      picture: file,
    });
  };

  const handleChangeAgency = (opts: IOption[]) => {
    if (!opts.some((d) => d.value === agency)) {
      const params = new URLSearchParams(searchParams);
      params.delete('agency');
      setSearchParams(new URLSearchParams(params));
    }
  };

  const errorsForm: any = errors;

  const errorMessage = useMemo(() => {
    if (!errorApi) return '';
    const msg = errorApi?.response?.data?.error || errorApi?.response?.data || 'Une erreur est survenue';
    return msg;
  }, [errorApi]);

  const color = useMemo(() => {
    const foundColor = roles.find((d: IOption) => d.value === (watchRole || 'Admin'))?.color || null;
    return foundColor;
  }, [roles, watchRole]);

  return (
    <div className={styles.container}>
      <form>
        <div className={`${styles.roles} ${user?._id === authUser?._id ? styles.disabled : ''}`}>
          {roles[0] && <StyledSelect
            name='role'
            control={control}
            // defaultValue={roles[0].value}
            options={roles}
            focusOnError={false}
            defaultColor={color}
            isDisabled={user?._id === authUser?._id}
          />}
        </div>
        <div className={styles.inputPicture}>
          <DropInputFile
            name='picture'
            label='+ Ajouter une image d’illustration'
            control={controlPicture}
            handleChange={handlePictureChange}
            color={color}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              label='Nom'
              name='profile.lastName'
              control={control}
              focusOnError={false}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errorsForm?.profile?.lastName?.message && (
              <ErrorField message={errorsForm.profile.lastName.message} />
            )}
          </div>
          <div className={styles.containerField}>
            <InputText
              label='Prénom'
              name='profile.firstName'
              control={control}
              focusOnError={false}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errorsForm?.profile?.firstName?.message && (
              <ErrorField message={errorsForm.profile.firstName.message} />
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputFormatPattern
              name="phone"
              label="Téléphone"
              control={control}
              format="## ## ## ## ##"
              rules={{
                pattern: {
                  value: /^(?:\s*\d){10}$/,
                  message: "Le champs de numéro de téléphone n'est pas valide",
                },
              }}
            />
            {errorsForm?.phone?.message && (
              <ErrorField message={errorsForm.phone.message} />
            )}
          </div>
          <div className={styles.containerField}>
            <InputEmail
              label='Mail'
              name='email'
              control={control}
              focusOnError={false}
              disabled={!!user}
              required
            />
            {errorsForm?.email?.message && (
              <ErrorField message={errorsForm.email.message} />
            )}
          </div>
        </div>
        {watchRole !== 'Admin' && (
          <>
            <hr />
            <div className={styles.row}>
              <div className={styles.containerField}>
                <StyledSelect
                  label='Agences'
                  name='agencies'
                  control={control}
                  options={agencies}
                  focusOnError={false}
                  isMulti
                  handleChange={(optAgency) => {
                    handleChangeAgency(optAgency);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {errorMessage && (
          <div className={styles.error}>
            <ErrorField message={errorMessage} />
          </div>
        )}
      </form>
      {watchRole === 'Sale' && (
        <SaleForm control={control} watch={watch} />
      )}
      {watchRole === 'Member' && (
        <MemberForm
          ref={memberFormRef}
          list={list}
          user={user}
        />
      )}
    </div>
  );
};

export default forwardRef(UserForm);
