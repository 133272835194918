import React, { useEffect } from 'react';
import { BsCheck } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import { useGetEstimatesList, useCreateEstimate } from '~/hooks/estimates';
import styles from './create-estimate.module.scss';

import {
  StyledSelect,
} from '~/lib/HooksFormFields';

const CreateEstimate = ({
  clientId,
  onSuccess,
} : {
  clientId?: string,
  onSuccess: (estimateId: string) => void,
}) => {
  const { control, setValue, handleSubmit } = useForm();
  const { data: list = {} } = useGetEstimatesList();
  const {
    isLoading,
    mutateAsync: createEstimate,
  } = useCreateEstimate();

  useEffect(() => {
    if (!clientId) return;
    setValue('client', clientId);
  }, [clientId]);

  const {
    agencies = [],
    clients = [],
  } = list;

  useEffect(() => {
    if (agencies.length === 1) {
      setValue('agency', agencies[0].value);
    }
  }, [agencies]);

  const submit = async (data: any) => {
    try {
      const { estimate } = await createEstimate(data);
      console.log(estimate);
      onSuccess(estimate._id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.form}>
      <h3>Créer un devis</h3>
      <div className={styles.fields}>
        <div className={styles.containerField}>
          <StyledSelect
            name='client'
            label='Choisir un client'
            control={control}
            options={clients}
            isDisabled={!!clientId}
            isSearchable
          />
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            name='agency'
            label='Agence'
            isDisabled={agencies.length === 1}
            control={control}
            options={agencies}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <button className='invisible'>Annuler</button>
        <button disabled={isLoading} onClick={handleSubmit(submit)}>
          <span className='icon'><BsCheck /></span>
          <span>Valider</span>
        </button>
      </div>
    </div>
  );
};

export default CreateEstimate;
