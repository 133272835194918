import React, { useEffect, useCallback } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useParams } from 'react-router-dom';
import { get } from '~/utils';
import styles from './general-infos.module.scss';

import { useGetEstimate, useUpdateEstimate, useGetEstimatesList } from '~/hooks/estimates';

import {
  InputText,
  InputNumber,
  StyledSelect,
  InputFormatPattern,
  InputEmail,
  ErrorField,
} from '~/lib/HooksFormFields';

const GeneralInfos = () => {
  const { id } = useParams();

  const {
    data: estimate = {},
  } = useGetEstimate(id || '');

  const { data: list = {} } = useGetEstimatesList();
  const { departments } = list;

  const {
    mutate: updateEstimate,
  } = useUpdateEstimate('Les modifications ont été prise en compte');

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...estimate,
    },
  });
  const errorsForm: any = errors;

  const submit = useCallback(async (data: FieldValues) => {
    updateEstimate({
      _id: id,
      ...data,
    });
  }, [estimate]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <div className={styles.generalInfos}>
      <section>
        <h4>Information sur le client</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name='worksite.name'
              label='Nom du chantier'
              control={control}
            />
          </div>
          <div className={styles.containerField}>
            <InputText
              name='worksite.city'
              label='Ville'
              control={control}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name='worksite.estimatedStartDate'
              label='Date démarrage prévisionnelle'
              control={control}
            />
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Contact côté client</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name='contact.lastName'
              label='Nom'
              control={control}
            />
          </div>
          <div className={styles.containerField}>
            <InputText
              name='contact.firstName'
              label='Prénom'
              control={control}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputFormatPattern
              name="contact.phone"
              label="Téléphone"
              control={control}
              format="## ## ## ## ##"
              rules={{
                pattern: {
                  value: /^(?:\s*\d){10}$/,
                  message: "Le champs de numéro de téléphone n'est pas valide",
                },
              }}
            />
          </div>
          <div className={styles.containerField}>
            <InputEmail
              name="contact.email"
              control={control}
              label="Adresse mail"
              placeholder='adressemail@mail.fr'
            />
          </div>
        </div>
        {!!Object.keys(errorsForm).length && (
          ['contact.phone', 'contact.email'].map((key) => (
            get(errorsForm, key) ? <div key={`error-${key}`} className={styles.errorMessage}>
              <ErrorField message={get(errorsForm, key).message} />
            </div> : null
          )).filter((d) => d)[0]
        )}
      </section>
      <hr />
      <section>
        <h4>Informations sur le chantier</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              name='worksite.department'
              label='Département'
              control={control}
              options={departments}
            />
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name='worksite.materialUsed'
              label='Matériel utilisé'
              control={control}
              options={[]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              name='worksite.load'
              label='Charge'
              control={control}
              options={[]}
            />
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name='worksite.stability'
              label='Stabilité'
              control={control}
              options={[]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              name='worksite.access'
              label='Moyens Accès'
              control={control}
              options={[]}
            />
          </div>
          <div className={`${styles.containerField} ${styles.duration}`}>
            <label>Durée global du chantier</label>
            <div className={styles.fields}>
              <InputNumber
                name='worksite.duration'
                control={control}
              />
              <StyledSelect
                name='worksite.durationUnit'
                placeholder='unité'
                control={control}
                options={[]}
              />
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Informations logistique</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              name='logistics.operationalBase'
              label='Base opérationnelle'
              control={control}
              options={[]}
            />
          </div>
          <p>
            Taux horaire: 46€
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <label>
              Remise sur la MO
            </label>
            <StyledSelect
              name='logistics.discountMO'
              control={control}
              options={[]}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default GeneralInfos;
