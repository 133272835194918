import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetEstimatesList } from '~/hooks/estimates';
import styles from './rental-form.module.scss';

import {
  InputNumber,
  Checkbox,
  StyledSelect,
} from '~/lib/HooksFormFields';

const RentalForm = () => {
  const { control } = useFormContext();
  const { data: list = { rental: {} } } = useGetEstimatesList();
  const {
    durations,
  } = list.rental;

  return (
    <div className={styles.rental}>
      <h4>Location</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <StyledSelect
            label='Durée'
            name='rental.duration'
            control={control}
            options={durations}
          />
        </div>
        <div className={styles.containerField}>
          <InputNumber
            name='rental.discount'
            label='Remise'
            control={control}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputNumber
            name='rental.price'
            label='Tarif'
            control={control}
          />
        </div>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='rental.globalPrice'
            label='Appliquer un tarif global'
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default RentalForm;
