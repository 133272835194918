import React from 'react';
import { useController, Control } from 'react-hook-form';
import styles from './input-email.module.scss';

interface InputEmailProps {
  name: string;
  control: Control<any>;
  required?: boolean;
  requiredMessage?: string;
  widthLabel?: number;
  inline?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  focusOnError?: boolean;
  disabled?: boolean;
}

const InputEmail: React.FC<InputEmailProps> = ({
  name,
  control,
  required = false,
  requiredMessage = 'Ce champs email est obligatoire',
  widthLabel = null,
  inline = false,
  label = null,
  placeholder = '',
  className,
  focusOnError = true,
  disabled = false,
}) => {
  const {
    field: {
      value = '',
      ref,
      onChange,
      onBlur,
    },
  } = useController({
    control,
    name,
    rules: {
      required: required ? requiredMessage : false,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Veuillez renseigner une adresse mail valide',
      },
    },
  });

  return (
    <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input-email']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input-email']}`}>
      { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>
        {label}
        {required && (<sup>*</sup>)}
      </label>}
      <input
        ref={focusOnError ? ref : null}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        className={styles.input}
        type="email"
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputEmail;
