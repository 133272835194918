import React, { useEffect, useMemo } from 'react';
import StickyBox from 'react-sticky-box';
import { useForm } from 'react-hook-form';
import {
  Routes, Route, useLocation,
  useParams,
  useNavigate,
  NavLink,
} from 'react-router-dom';
import { format } from 'date-fns';
import { CiCircleCheck } from 'react-icons/ci';
import { fr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import ColorScale from 'color-scales';
import styles from './estimate.module.scss';
import {
  useGetEstimate, useCreateEstimatePhase,
  useGetEstimatesList,
} from '~/hooks/estimates';

import Loader from '~/components/Loader';
import GeneralSidebar from '~/components/estimate/GeneralSidebar';
import PhaseSidebar from '~/components/estimate/PhaseSidebar';

import GeneralInfos from './general-informations';
import Phase from './phase';
import BackButton from '~/components/BackButton';

import {
  StyledSelect,
} from '~/lib/HooksFormFields';
import { IPhase } from '~/types/estimates';

// devis/65defe5c41dbeb16e77cde00/ -> info général
// devis/65defe5c41dbeb16e77cde00/O/dimension

const colorScale = new ColorScale(0, 100, ['#EAEDFA', '#acaeb6']);

const Estimate = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isLoading,
    data: estimate,
  } = useGetEstimate(id || '');

  const {
    mutateAsync: createEstimatePhase,
  } = useCreateEstimatePhase();

  const {
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      status: estimate?.status,
    },
  });

  const { data: list = {} } = useGetEstimatesList();
  const { status } = list;

  useEffect(() => {
    if (!getValues('status') && estimate?.status) {
      setValue('status', estimate.status);
    }
  }, [estimate]);

  const generalInfosActive = useMemo(() => (
    location.pathname.split('/').length < 4
  ), [location.pathname]);

  const handleCreatePhase = async () => {
    if (!id) return;
    try {
      const { estimate: updatedEstimate } = await createEstimatePhase(id);
      const phases: IPhase[] = updatedEstimate?.phases || [];
      navigate(`/estimates/${id}/${phases[phases.length - 1]._id}`);
    } catch {
      toast.error('La phase n\'a pu être créé');
    }
  };

  const getIsActive = (pathname: string) => {
    let active = false;
    if (location.pathname === `/estimates/${id}${pathname}`) active = true;
    return active;
  };

  return (
    <div className={styles.estimate}>
      {isLoading && (
        <div className={styles.loader}><Loader /></div>
      )}
      {estimate && (
        <div className={styles.content}>
          <StickyBox>
            <div className={styles.sidebar}>
              <div className={styles.back}>
                <BackButton path='/estimates' />
                <NavLink to='/estimates'>Retour à la liste des devis</NavLink>
              </div>
              <div className={styles.updatedAt}>
                <CiCircleCheck size={20} />
                <p>Dernière modification {format(estimate.updatedAt, "dd.MM.yyyy à HH'h'mm", { locale: fr })}</p>
              </div>
              <div className={styles.status}>
                <label>Statut</label>
                <StyledSelect
                  name='status'
                  control={control}
                  options={status}
                  isDisabled
                  withColorDot
                />
              </div>
              <hr />
              {generalInfosActive
                ? <GeneralSidebar estimate={estimate} />
                : <PhaseSidebar />
              }
            </div>
          </StickyBox>
          <div className={styles.containers}>
            <div className={styles.navTabs}>
              <NavLink
                to=''
                style={{
                  position: 'relative',
                  zIndex: (estimate?.phases?.length || 0) + 1,
                  backgroundColor: getIsActive('') ? 'white' : colorScale.getColor(0).toRGBString(),
                }}
              >
                Informations de générales
              </NavLink>
              {(estimate?.phases || []).map((phase: any, index: number) => (
                <NavLink
                  to={phase._id}
                  key={`phase-${phase._id}`}
                  style={{
                    position: 'relative',
                    zIndex: estimate.phases.length - index,
                    backgroundColor: getIsActive(`/${phase._id}`) ? 'white' : colorScale.getColor(
                      (100 / estimate.phases.length) * (index + 1),
                    ).toRGBString(),
                  }}
                >
                  {phase.name || `Phase ${index + 1}`}
                </NavLink>
              ))}
              <a className={styles.add} onClick={() => handleCreatePhase()}>
                Ajouter une nouvelle phase
              </a>
            </div>
            <div className={styles.tabs}>
              <Routes>
                <Route path='/' element={<GeneralInfos />} />
                <Route path='/:phaseId' element={<Phase />} />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Estimate;
