/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  IUser,
  ArgsGetUsers,
} from '~/types/users';

const getUsers = async (params: ArgsGetUsers) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.users;
};

const getUser = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.user;
};

const getUsersList = async (params?: { agencies: string[] } | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/list`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.list;
};

const createUser = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');

  const { picture, ...dataUser } = data;

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/users`,
    dataUser,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  let responsePicture: any = {};
  if (picture && response.data.user._id) {
    const formdata = new FormData();
    formdata.append('picture', picture as any);
    responsePicture = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/picture/${response.data.user._id}`,
      formdata,
      {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
  return responsePicture?.data || response.data;
};

const updateUser = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

// eslint-disable-next-line max-len
const updateUserPicture = async (data: { _id: string, picture: Blob | null }): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, picture } = data;
  const formdata = new FormData();
  formdata.append('picture', picture as any);
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/users/picture/${_id}`,
    formdata,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

// eslint-disable-next-line max-len
const updateUserDocuments = async (data: { _id: string, documents: FormData }): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, documents } = data;
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/users/documents/${_id}`,
    documents,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const deleteUser = async (id: string): Promise<{ message: string }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    },
  );
  return response.data;
};

export {
  getUsers,
  getUser,
  getUsersList,
  createUser,
  updateUser,
  updateUserPicture,
  updateUserDocuments,
  deleteUser,
};
