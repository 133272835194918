import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ResetPwdForm from '~/components/form/ResetPwdForm';
import signinImage from '~/assets/images/signin-image.svg';
import getErrorAuthMessage from '~/utils/auth-helpers';
import { useResetPassword } from '~/hooks/auth';
import styles from './reset-password.module.scss';

const ResetPassword = () => {
  const { token } = useParams();
  const {
    mutate: resetPassword, isSuccess, error,
  } = useResetPassword();
  const errorApi: any = error;

  const submitReset = async (data: { password: string }) => {
    if (!token) return;
    try {
      try {
        await resetPassword({
          password: data.password,
          token,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const errorMessage = useMemo(() => {
    if (!error) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error);
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.resetPassword}>
      <div className={styles.container}>
        <div className={styles.picture}>
          <img src={signinImage} />
        </div>
        <div className={styles.containerForm}>
          <h1>Bienvenue sur LVTec Devis</h1>
          <div className={styles.form}>
            <h2>Mot de passe oublié</h2>
            <ResetPwdForm
              submit={submitReset}
              signinLink={'/login'}
              succeedMessage={isSuccess ? 'Votre mot de passe a été réinitialisé.' : ''}
              errorMessage={errorMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
