import React from 'react';
import { useForm, FieldValues } from 'react-hook-form';

import {
  InputEmail,
  InputPassword,
  ErrorField,
} from '../../../lib/HooksFormFields';

import styles from './signin-form.module.scss';

interface ISignInForm {
  errorMessage?: string,
  registerLink?: string,
  forgotPwdLink?: string,
  submit: (data: FieldValues) => void,
}

export interface CredentialsRequest {
  email: string
  password: string
}

const SignInForm = ({
  errorMessage,
  submit,
}: ISignInForm) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(data: FieldValues) {
    submit(data as CredentialsRequest);
  }

  function handlePressEnter(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['signin-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles.containerField}>
          <InputEmail
            name="email"
            control={control}
            required
            label="Adresse mail"
            placeholder='adressemail@mail.fr'
            className="login"
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        <div className={styles.containerField}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Mot de passe"
            placeholder='**********'
            className="login"
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
      </div>
      <div className={styles.containerSubmit}>

        {(!errors.email && !errors.password) && errorMessage
          && (
            <div className={styles.error}>
              <ErrorField message={errorMessage} />
            </div>
          )}
        <div className={styles.containerBtn}>
          <button onClick={handleSubmit(onSubmit)}>
            <span>Connexion</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
