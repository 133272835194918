/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQuery,
  // UseMutateFunction,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getAgency,
  getAgencies,
  getAgencyPricing,
  updateAgencyPricing,
  getAgencyUsers,
} from '../../api/agencies';
import { IMetaPagination } from '~/types/global';
import {
  ArgsGetAgencies, ArgsGetAgencyPricing, ArgsGetAgencyUsers, IAgency, IPricing,
} from '~/types/agencies';
import { IUser } from '~/types/users';

const useGetAgencies = (params: ArgsGetAgencies) => useQuery({
  queryKey: ['agencies', params],
  queryFn: async (): Promise<{ agencies: IAgency[], meta: IMetaPagination } | null> => getAgencies(params),
  keepPreviousData: true,
});

const useGetAgency = (id: string) => useQuery({
  queryKey: ['agency', id],
  queryFn: async (): Promise<IAgency | null> => getAgency(id),
  keepPreviousData: true,
});

const useGetAgencyPricing = (params: ArgsGetAgencyPricing) => useQuery({
  queryKey: ['agency-pricing', params],
  queryFn: async (): Promise<IPricing[] | null> => getAgencyPricing(params),
  keepPreviousData: true,
});

const useUpdateAgencyPricing = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { _id: string, location: string, key: string, value: number }) => updateAgencyPricing(data),
    onSuccess: async ({ agency }) => {
      queryClient.invalidateQueries({ queryKey: ['agency'] });
      queryClient.invalidateQueries({ queryKey: ['agencies'], exact: false });

      queryClient.setQueryData(['agency', agency._id], agency);
      if (successMessage) toast(successMessage);
    },
  });
};

const useGetAgencyUsers = (params: ArgsGetAgencyUsers) => useQuery({
  queryKey: ['agency-users', params],
  queryFn: async (): Promise<IUser[] | null> => getAgencyUsers(params),
  keepPreviousData: true,
});

export {
  useGetAgency,
  useGetAgencies,
  useGetAgencyPricing,
  useUpdateAgencyPricing,
  useGetAgencyUsers,
};
