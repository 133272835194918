import React, {
  useState, useRef,
  MouseEvent,
  DragEvent, ChangeEvent,
} from 'react';
import { useController, Control } from 'react-hook-form';
// import { MdDelete } from 'react-icons/md';
import styles from './drop-input-file.module.scss';

const DropInputFile = ({
  name,
  control,
} : {
  name: string,
  control: Control,
}) => {
  const {
    field: {
      value,
      onChange,
    },
  } = useController({
    name,
    control,
  });

  const [dragActive, setDragActive] = React.useState(false);
  const [fileName, setFileName] = useState(value?.name || '');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFiles = (files: any) => {
    const file = files[0];
    if (file) {
      onChange(file);
      setFileName(file.name);
    }
  };

  // handle drag events
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setFileName('');
  //   onChange(null);
  // };

  // triggers the input when the button is clicked
  const onButtonClick = (e: MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <div className={styles.dropInputFile}>
      <div className={`${styles.container}${fileName ? ` ${styles.withFile}` : ''}`}
        onDragEnter={handleDrag}
        // onClick={onButtonClick}
        onSubmit={(e) => e.preventDefault()}
      >
        <input ref={inputRef} type="file" multiple={false} onChange={handleChange} />
        <label htmlFor="input-file-upload" className={dragActive ? styles.dragActive : '' }>
          <div>
            {fileName ? (
            <>
              {/* <div className={styles.delete} onClick={handleDelete}>
                <MdDelete size={20} />
              </div> */}
              <p>{fileName}</p>
              </>
            ) : (
              <p>
                Importer un document
              </p>
            )}
            <button onClick={onButtonClick}>Parcourir</button>
          </div>
        </label>
        { dragActive && (
          <div className={styles.dragFile}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}

      </div>
    </div>
  );
};

export default DropInputFile;
