import React from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import ListUsers from './list';
import DetailUser from './detail';
import styles from './users.module.scss';
import CreateUser from './create';

const Users = () => (
  <div className={styles.users}>
    <Routes>
      <Route path='/' element={<ListUsers />} />
      <Route path='/create' element={<CreateUser />} />
      <Route path='/:id' element={<DetailUser />} />
    </Routes>
  </div>
);

export default Users;
