/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  ArgsGetAgencies, ArgsGetAgencyPricing, ArgsGetAgencyUsers, IAgency,
} from '~/types/agencies';

const getAgencies = async (params: ArgsGetAgencies) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/agencies`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data;
};

const getAgency = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/agencies/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.agency;
};

const getAgencyPricing = async (params: ArgsGetAgencyPricing) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { id, ...requestParams } = params;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/agencies/${id}/pricing`,
    {
      headers: {
        Authorization: token,
      },
      params: requestParams,
    },
  );
  return data.pricing;
};

// eslint-disable-next-line max-len
const updateAgencyPricing = async (data: { _id: string, location: string, key: string, value: number }): Promise<{ agency: IAgency }> => {
  const token = localStorage.getItem('token');
  const {
    _id,
    key,
    value,
    location,
  } = data;

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/agencies/${_id}/pricing`,
    {
      key,
      value,
      location,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const getAgencyUsers = async (params: ArgsGetAgencyUsers) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { id, ...requestParams } = params;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/agencies/${id}/users`,
    {
      headers: {
        Authorization: token,
      },
      params: requestParams,
    },
  );
  return data.users;
};

export {
  getAgency,
  getAgencies,
  getAgencyPricing,
  updateAgencyPricing,
  getAgencyUsers,
};
