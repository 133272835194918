import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetEstimatesList } from '~/hooks/estimates';
import styles from './dimension-form.module.scss';

import {
  InputNumber,
  StyledSelect,
  Checkbox,
} from '~/lib/HooksFormFields';

const DimensionForm = () => {
  const { control, setValue, watch } = useFormContext();
  const { data: list = { dimension: {} } } = useGetEstimatesList();
  const {
    ratioUsages,
    accordingTo,
  } = list.dimension;

  const accordingToValue = watch('dimension.accordingTo');

  return (
    <div className={styles.dimension}>
      <h4>Dimension</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputNumber
            name='dimension.length'
            label='Longeur'
            placeholder='en m'
            control={control}
          />
        </div>
        <div className={styles.containerField}>
          <InputNumber
            name='dimension.width'
            label='Largeur'
            placeholder='en m'
            control={control}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputNumber
            name='dimension.height'
            label='Hauteur'
            placeholder='en m'
            control={control}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputNumber
            name='dimension.scaffoldingSurface'
            label='Surface échafaudages'
            placeholder='en m'
            control={control}
          />
        </div>
        <div className={styles.containerField}>
          <InputNumber
            name='dimension.netSurface'
            label='Longeur'
            placeholder='en m'
            control={control}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <StyledSelect
            name='dimension.accordingTo'
            label='Selon'
            control={control}
            options={accordingTo}
            handleChange={(opt) => {
              if (opt.value === 'Ratio') {
                setValue('dimension.countingBE', null);
                setValue('dimension.worksiteAdaptation', null);
                setValue('dimension.counting', null);
              } else if (opt.value === 'Décompte BE') {
                setValue('dimension.ratioUsage', null);
                setValue('dimension.guardrailInterior', null);
                setValue('dimension.counting', null);
              } else if (opt.value === 'Décompte manuel') {
                setValue('dimension.ratioUsage', null);
                setValue('dimension.guardrailInterior', null);
                setValue('dimension.countingBE', null);
                setValue('dimension.worksiteAdaptation', null);
              }
            }}
          />
        </div>
        <div className={styles.containerField}>
          <InputNumber
            name='dimension.tonnage'
            label='Tonnes'
            placeholder='en m'
            control={control}
          />
        </div>
      </div>
      {accordingToValue === 'Ratio' && (
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              label='Utilisation du ratio pour une structure en'
              name='dimension.ratioUsage'
              control={control}
              options={ratioUsages}
            />
          </div>
          <div className={`${styles.containerField} ${styles.checkbox}`}>
            <Checkbox
              name='dimension.guardrailInterior'
              label='Ajouter gardes-corps interieurs'
              control={control}
            />
          </div>
        </div>
      )}
      {accordingToValue === 'Décompte BE' && (
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputNumber
              name='dimension.countingBE'
              label='Décompte BE'
              control={control}
            />
          </div>
          <div className={styles.containerField}>
            <InputNumber
              name='dimension.worksiteAdaptation'
              label='Adaptaion chantier'
              placeholder='en %'
              control={control}
            />
          </div>
        </div>
      )}
      {accordingToValue === 'Décompte manuel' && (
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputNumber
              name='dimension.counting'
              label='Décompte'
              control={control}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DimensionForm;
