import React from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import ListClients from './list';
import DetailClient from './detail';
import styles from './clients.module.scss';

const Clients = () => (
  <div className={styles.clients}>
    <Routes>
      <Route path='/' element={<ListClients />} />
      <Route path='/:id' element={<DetailClient />} />
    </Routes>
  </div>
);

export default Clients;
