/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  createEstimate,
  createEstimatePhase,
  getEstimate,
  getEstimates,
  getEstimatesList,
  updateEstimate,
  updateEstimatePhase,
  addEstimateNote,
  updateEstimateNote,
  deleteEstimateNote,
  getStatsEstimates,
} from '../../api/estimates';
import {
  INote,
  ArgsGetEstimates, IEstimate,
  IPhase,
} from '~/types/estimates';

const useGetEstimates = (params: ArgsGetEstimates) => useQuery({
  queryKey: ['estimates', params],
  queryFn: async (): Promise<IEstimate[]> => getEstimates(params),
  keepPreviousData: true,
});

const useGetStatsEstimates = () => useQuery({
  queryKey: ['estimates-stats'],
  queryFn: async (): Promise<any | null> => getStatsEstimates(),
  keepPreviousData: true,
});

const useGetEstimatesList = () => useQuery({
  queryKey: ['estimates-list'],
  queryFn: async (): Promise<any | null> => getEstimatesList(),
  keepPreviousData: true,
});

const useGetEstimate = (id: string) => useQuery({
  queryKey: ['estimate', id],
  queryFn: async (): Promise<IEstimate | null> => getEstimate(id),
  keepPreviousData: true,
});

const useCreateEstimate = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IEstimate>) => createEstimate(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({ queryKey: ['estimates-stats'] });
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.invalidateQueries({ queryKey: ['client', estimate.client] });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateEstimate = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IEstimate>) => updateEstimate(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({ queryKey: ['estimates-stats'] });

      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.invalidateQueries({ queryKey: ['client', estimate.client._id] });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useCreateEstimatePhase = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (estimateId: string) => createEstimatePhase(estimateId),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateEstimatePhase = (successMessage?: string) => {
  const queryEstimate = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IPhase> & { estimateId : string }) => updateEstimatePhase(data),
    onSuccess: async ({ estimate }) => {
      queryEstimate.invalidateQueries({
        queryKey: ['estimates'],
        exact: false,
      });
      queryEstimate.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useAddEstimateNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => addEstimateNote(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateEstimateNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => updateEstimateNote(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDeleteEstimateNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { noteId: string, estimateId: string }) => deleteEstimateNote(data),
    onSuccess: async ({ estimate }) => {
      queryClient.setQueryData(['estimate', estimate._id], estimate);
      if (successMessage) toast(successMessage);
    },
  });
};

export {
  useGetEstimate,
  useGetStatsEstimates,
  useGetEstimates,
  useGetEstimatesList,
  useCreateEstimate,
  useUpdateEstimate,
  useCreateEstimatePhase,
  useUpdateEstimatePhase,
  useAddEstimateNote,
  useUpdateEstimateNote,
  useDeleteEstimateNote,
};
