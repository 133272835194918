import React from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { IoMdAdd } from 'react-icons/io';
import { useGetAgencyUsers } from '~/hooks/agencies';
import UsersTable from '~/components/table/UsersTable';
import InputSearch from '~/components/InputSearch';
import styles from './users.module.scss';

const Users = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: users } = useGetAgencyUsers({
    id,
    ...paramsObject,
    search: debouncedSearch,
  });

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    // console.log(sort);
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.users}>
      <div className={styles.header}>
        <h4>{(users || []).length} Membre{(users || []).length > 1 && 's'}</h4>
        <div className={styles.actions}>
          <InputSearch
            defaultValue={search}
            handleSearch={handleSearch}
            label={'Rechercher un membre à ajouter'}
          />
          <button onClick={() => navigate(`/users/create?agency=${id}`)}>
            <span className='icon'><IoMdAdd /></span>
            <span>Créer un membre</span>
          </button>
        </div>
      </div>
      <UsersTable
        users={users || []}
        handleSorting={handleSorting}
      />
    </div>
  );
};

export default Users;
