import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EstimatesTable from '~/components/table/EstimatesTable';
import styles from './estimates.module.scss';
import { useGetEstimates } from '~/hooks/estimates';

const Estimates = () => {
  const { id = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);
  const { data: estimates } = useGetEstimates({
    agency: id,
    ...paramsObject,
  });

  const handleSorting = (sort: any) => {
    if (!sort) return;
    // console.log(sort);
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.users}>
      <EstimatesTable
        estimates={estimates || []}
        handleSorting={handleSorting}
      />
    </div>
  );
};

export default Estimates;
