import React from 'react';
import {
  Routes, Route, useParams, NavLink, useLocation,
} from 'react-router-dom';
import { useGetAgency } from '~/hooks/agencies';
import Pricing from './pricing';
import Users from './users';
import Estimates from './estimates';
import BackButton from '~/components/BackButton';
import styles from './agency.module.scss';

const Agencies = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data: agency } = useGetAgency(id || '');

  return (
    <div className={styles.agency}>
      <header>
        <div className={styles.content}>
          <div className={styles.titlePage}>
            <BackButton />
            {agency && (
              <span className={styles.agency}>
                <h1>{agency.name}</h1>
                <p>{agency.location}</p>
              </span>
            )}
          </div>
          <nav>
            <ul>
              <li>
                <NavLink className={() => (location.pathname === `/agencies/${id}` ? styles.active : '')} to={`/agencies/${id}`}>
                  Tarifs
                </NavLink>
              </li>
              <li>
                <NavLink className={() => (location.pathname === `/agencies/${id}/users` ? styles.active : '')} to={`/agencies/${id}/users`}>
                  Membres
                </NavLink>
              </li>
              <li>
                <NavLink className={() => (location.pathname === `/agencies/${id}/estimates` ? styles.active : '')} to={`/agencies/${id}/estimates`}>
                  Devis
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div className={styles.content}>
        <Routes>
          <Route path='/*' element={<Pricing />} />
          <Route path='/users' element={<Users />} />
          <Route path='/estimates' element={<Estimates />} />
        </Routes>
      </div>
    </div>
  );
};

export default Agencies;
