import React, { useRef } from 'react';
import { IoMdInformation } from 'react-icons/io';
import PositionalModal from '../PositionalModal';
import styles from './tooltip.module.scss';

const Tooltip = ({
  children,
}: { children: React.ReactNode }) => {
  type ModalRefHandler = React.ElementRef<typeof PositionalModal>;
  const modalRef = useRef<ModalRefHandler>(null);
  const cellRef = useRef<HTMLParagraphElement>(null);
  return (
    <>
      <div className={styles.tooltip} ref={cellRef}>
        <div
          onMouseEnter={() => modalRef.current?.open()}
          onMouseLeave={() => modalRef.current?.close()}
        >
          <IoMdInformation />
        </div>
      </div>
      <PositionalModal
        ref={modalRef}
        parent={cellRef}
        containerStyles={{
          pointerEvents: 'none',
          padding: 5,
        }}
      >
        <div className={styles.content}>
          {children}
        </div>
      </PositionalModal>
    </>
  );
};

export default Tooltip;
