import React, { useCallback } from 'react';
import Select from 'react-select';
import { Controller, Control } from 'react-hook-form';
import { IOption } from '../../../../types/options';
import styles from '../styled-select.module.scss';
import {
  styleBase,
  styleBold,
} from '../style';

interface SelectProps {
  name: string,
  control: Control<any>,
  options: IOption[],
  rules?: any,
  widthLabel?: string | null,
  inline?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  isDisabled?: boolean,
  withColorDot?: boolean,
  label?: string | null,
  className?: string,
  isClearable?: boolean,
  isSearchable?: boolean,
  focusOnError?: boolean,
  defaultValue?: any,
  defaultColor?: string | null,
  handleChange?: (val: any) => void,
  prop?: any,
}

const formatOptionLabel = ({
  label, value, withColorDot, getColor,
}
: { label: string, value: string, withColorDot: boolean, getColor: (value: string) => string }) => (
  <>
    {withColorDot && (
      <div
        style={getColor(value) ? { backgroundColor: getColor(value) } : {}}
        className={styles.dot}
      />
    )}
    <span>{label}</span>
  </>
);

const StyledSelect = ({
  name,
  control,
  rules,
  options = [],
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  className,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  withColorDot = false,
  focusOnError = true,
  defaultValue,
  defaultColor = null,
  isMulti = false,
  handleChange,
  ...props
}: SelectProps) => {
  function getClassName(color: string | null) {
    let style;
    if (className === 'bold') style = styleBold;
    else style = styleBase;
    return style(color);
  }

  const getColor = useCallback((value: string | number) => {
    const color = options.find((d: IOption) => d.value === value)?.color || defaultColor;
    return color;
  }, [options]);

  const getValue = (field: any) => {
    let value: any = null;

    if (isMulti && Array.isArray(field.value)) {
      value = options.filter((option: IOption) => field.value.includes(option.value));
    } else if (field.value || field.value === '') {
      value = options.find((option: IOption) => option.value === field.value);
    }
    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && (
            <label style={widthLabel ? { minWidth: widthLabel } : {}}>
              {label}
              {rules && (<sup>*</sup>)}
            </label>
          )}
          <Select
            {...field}
            {...props}
            isMulti={isMulti}
            ref={focusOnError ? field.ref : null}
            value={getValue(field)}
            // value={field?.value}
            options={options}
            onChange={(opt: any) => {
              field.onChange(isMulti ? opt.map((d: IOption) => d.value) : (opt?.value ?? null));
              if (handleChange) handleChange(opt);
            }}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isClearable={isClearable}
            isSearchable={isSearchable}
            className={`${styles.select}`}
            placeholder={placeholder}
            formatOptionLabel={(p) => formatOptionLabel({ ...p, withColorDot, getColor })}
            styles={getClassName(!withColorDot ? getColor(field.value) : null)}
          />
        </div>
      )}
    />
  );
};

export default StyledSelect;
