import React, { useCallback } from 'react';
import { Control, WatchInternal } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  StyledSelect,
} from '~/lib/HooksFormFields';
import { useGetUsersList } from '~/hooks/users';
import styles from './sale-form.module.scss';
import { IOption } from '~/types/options';

const SaleForm = (
  { control, watch } : { control: Control<any>, watch: WatchInternal<any> },
) => {
  const { id } = useParams();
  const watchAgency = watch('agencies');

  const { data: list = [] } = useGetUsersList(watchAgency ? { agencies: watchAgency } : null);
  const watchReferrer1 = watch('referrer1');
  const watchReferrer2 = watch('referrer2');

  const {
    referrers = [],
    limitsEstimate = [],
    workforceDiscounts = [],
    transportDiscounts = [],
    locationDiscounts = [],
  } = list;

  const optionsReferrers = useCallback((referrer: string) => {
    const exclude = referrer === 'referrer1' ? watchReferrer2 : watchReferrer1;

    return exclude
      ? referrers
        .filter((d: IOption) => (d.value !== exclude && d.value !== id))
      : referrers
        .filter((d: IOption) => d.value !== id);
  }, [referrers, watchReferrer1, watchReferrer2]);

  return (
    <div className={styles.saleForm}>
      <div className={styles.form}>
        <h4>Spécification devis</h4>
        <div className={styles.containerField}>
          <StyledSelect
            label="Limite d'envoi de devis"
            name='limitEstimate'
            control={control}
            options={limitsEstimate}
            focusOnError={false}
            isClearable
          />
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            label="Remise main d’oeuvre"
            name='workforceDiscount'
            control={control}
            options={workforceDiscounts}
            focusOnError={false}
          />
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            label="Remise transport"
            name='transportDiscount'
            control={control}
            options={transportDiscounts}
            focusOnError={false}
          />
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            label="Remise location"
            name='locationDiscount'
            control={control}
            options={locationDiscounts}
            focusOnError={false}
          />
        </div>
      </div>
      <div className={styles.form}>
        <h4>Responsable</h4>
        <div className={styles.containerField}>
          <StyledSelect
            label="Responsable 1"
            name='referrer1'
            control={control}
            options={optionsReferrers('referrer1')}
            focusOnError={false}
            isClearable
          />
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            label="Responsable 2"
            name='referrer2'
            control={control}
            options={optionsReferrers('referrer2')}
            focusOnError={false}
            isClearable
          />
        </div>
      </div>
    </div>
  );
};

export default SaleForm;
