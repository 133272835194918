import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { useDebounceValue } from 'usehooks-ts';
import { useGetUsers } from '~/hooks/users';
import UsersTable from '~/components/table/UsersTable';
import InputSearch from '~/components/InputSearch';
import styles from './users.module.scss';

const ListUsers = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: users } = useGetUsers({
    ...paramsObject,
    search: debouncedSearch,
  });

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    // console.log(sort);
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.content}>
      <div className={styles.listUsers}>
        <div className={styles.header}>
          <h1>Les Membres</h1>
          <div className={styles.actions}>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
              label='Rechercher'
            />
            <button onClick={() => navigate('/users/create')}>
              <span className='icon'><IoMdAdd /></span>
              <span>Créer un membre</span>
            </button>
          </div>
        </div>
        <UsersTable
          users={users || []}
          handleSorting={handleSorting}
        />
      </div>
    </div>
  );
};

export default ListUsers;
