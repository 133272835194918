import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { IoClose } from 'react-icons/io5';
import styles from './modal.module.scss';

interface ModalRefProps {
  open: () => void,
  close: () => void,
}

const Modal = forwardRef<
ModalRefProps,
{ width?: Number, children: React.ReactNode, handleClose?:() => void }
>(({ width = 310, children, handleClose }, ref) => {
    const modalRoot = document.getElementById('modal');
    const [isOpened, setIsOpened] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => setIsOpened(true),
      close: () => setIsOpened(false),
    }));

    return (
    <>
      {(modalRoot && isOpened) && (
        <>
          {createPortal(
            <>
              <div className={styles['overlay-modal']} onClick={() => {
                setIsOpened(false);
                if (typeof handleClose === 'function') handleClose();
              }}/>
              <div className={styles.modal} style={{ width: `${width}px` }}>
                <div className={styles.close} onClick={() => {
                  setIsOpened(false);
                  if (typeof handleClose === 'function') handleClose();
                }}>
                  <IoClose size={24} />
                </div>
                {children}
              </div>
            </>,
            modalRoot,
          )}
        </>
      )}
    </>
    );
  });

export default Modal;
