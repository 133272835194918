/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { IUser } from '~/types/users';

const signIn = async (email: string, password: string): Promise<{ user: IUser, token: string }> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
    email,
    password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

const forgotPassword = async (email: string): Promise<unknown> => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
    email,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

const resetPassword = async (password: string, token: string): Promise<unknown> => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password/${token}`, {
    password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

const getUserProfile = async (): Promise<IUser | null> => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/profile`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const updateProfile = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/profile`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  signIn,
  forgotPassword,
  resetPassword,
  getUserProfile,
  updateProfile,
};
