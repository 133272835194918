import React, { useEffect, useCallback } from 'react';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useUpdateEstimatePhase } from '~/hooks/estimates';
import styles from './phase-form.module.scss';

import {
  InputText,
} from '~/lib/HooksFormFields';
import { IPhase } from '~/types/estimates';

import DimensionForm from './DimensionForm';
import MobilizationForm from './MobilizationForm';
import RentalForm from './RentalForm';

const PhaseForm = ({
  estimateId,
  phase,
} : {
  estimateId: string,
  phase: IPhase
}) => {
  const {
    mutate: updateEstimatePhase,
  } = useUpdateEstimatePhase('Les modifications ont été prise en compte');

  const methods = useForm({
    defaultValues: {
      ...phase,
    },
  });

  const submit = useCallback(async (data: FieldValues) => {
    updateEstimatePhase({
      estimateId,
      _id: phase._id,
      ...data,
    });
  }, [phase]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => methods.watch(() => {
    methods.handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <FormProvider {...methods}>
      <div className={styles.form}>
        <div className={`${styles.containerField} ${styles.name}`}>
          <InputText
            name='name'
            control={methods.control}
          />
        </div>
        <div className={styles.containerField}>
          <InputText
            name='description'
            label='Description'
            placeholder='Description de la phase...'
            control={methods.control}
          />
        </div>
        <section>
          <DimensionForm />
          <hr />
          <MobilizationForm />
          <hr />
          <RentalForm />
          <hr />
        </section>
      </div>
    </FormProvider>
  );
};

export default PhaseForm;
