import React from 'react';
import { IoSearch } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { InputText } from '../../lib/HooksFormFields';
import styles from './input-search.module.scss';

const InputSearch = (
  {
    defaultValue = '',
    label = 'Rechercher...',
    handleSearch,
  } : {
    defaultValue?: string,
    label?: string,
    handleSearch : (str: string) => void,
  },
) => {
  const { control } = useForm({
    defaultValues: { search: defaultValue },
  });
  return (
    <div className={styles.inputSearch}>
      <InputText
        name='search'
        placeholder={label}
        control={control as any}
        handleChange={(value) => handleSearch(value)}
      />
      <IoSearch size={20} />
    </div>
  );
};

export default InputSearch;
