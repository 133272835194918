import React from 'react';
import { NavLink } from 'react-router-dom';
import { LuLogOut } from 'react-icons/lu';
import { useSignOut, useUserProfile } from '../../hooks/auth';
import styles from './navbar.module.scss';
import { IUser } from '~/types/users';

const Navbar = () => {
  const signOut = useSignOut();
  const { data } = useUserProfile();
  const user: Partial<IUser> = data || {};
  return (
    <div className={styles.navbar}>
      <ul>
        <li>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='' >Accueil</NavLink>
        </li>
        {user?.role === 'Admin' && (
          <>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='agencies' >Agences</NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='users' >Membres</NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='clients' >Clients</NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='estimates' >Devis</NavLink>
        </li>
      </ul>
      <div className={styles.profile}>
        <NavLink className={styles.user} to='/account' >
          <div className={styles.initial}>
            <span>
              {user.profile?.firstName[0]}
              {user.profile?.lastName[0]}
            </span>
          </div>
          <p>{user.profile?.firstName} {user.profile?.lastName}</p>
        </NavLink>
        <button className='invisible' onClick={signOut}>
          <LuLogOut size={26} />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
