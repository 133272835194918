import React, {
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { IoMdAdd } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './create.module.scss';
import {
  useCreateUser,
  useGetUsersList,
  useUpdateUserDocuments,
} from '~/hooks/users';
import BackButton from '~/components/BackButton';
import UserForm from '~/components/form/UserForm';

const CreateUser = () => {
  const [searchParams] = useSearchParams();
  const agency = searchParams.get('agency');
  const navigate = useNavigate();
  const userFormRef = useRef<any>(null);
  const { data: list = [] } = useGetUsersList(agency ? { agencies: [agency] } : null);
  const {
    mutate: updateUserDocuments,
  } = useUpdateUserDocuments();

  // Create
  const {
    mutateAsync: createUser,
    isSuccess,
    isLoading: isPostLoading,
  } = useCreateUser();

  const submitCreateUser = async () => {
    const { userData, documents } = await userFormRef.current.getData();
    if (userData) {
      const { user } = await createUser(userData);
      if (user) {
        updateUserDocuments({
          _id: user._id,
          documents,
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess) navigate('/users');
  }, [isSuccess]);

  const backButtonPath = useMemo(() => {
    if (agency) return `/agencies/${agency}/users`;
    return '/users';
  }, [agency]);

  return (
    <div className={styles.createlUser}>
      <div className={styles.header}>
        <div className={styles.content}>
          <div className={styles.titlePage}>
            <BackButton path={backButtonPath} />
            <h1>Créer un membre</h1>
          </div>
          <button
            disabled={isPostLoading}
            onClick={submitCreateUser}
          >
            <span className='icon'><IoMdAdd /></span>
            <span>Créer un membre</span>
          </button>
        </div>
      </div>
      <div className={styles.content}>
        {list?.roles && (
          <UserForm ref={userFormRef} agency={agency} list={list} />
        )}
      </div>
    </div>
  );
};

export default CreateUser;
