import { useCallback } from 'react';
import {
  useQueryClient,
  useMutation,
  useQuery,
  // UseMutateFunction,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  signIn, getUserProfile, forgotPassword, resetPassword,
  updateProfile,
} from '../../api/auth';
import { IUser } from '~/types/users';

const useSignIn = () => useMutation<any, unknown, {
  email: string, password: string
}, unknown>(({
  email,
  password,
}) => signIn(email, password));

const useForgotPassword = () => useMutation<any, unknown, {
  email: string
}, unknown>(({
  email,
}) => forgotPassword(email));

const useResetPassword = () => useMutation<any, unknown, {
  password: string,
  token: string
}, unknown>(({
  password,
  token,
}) => resetPassword(password, token));

const useSignOut = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSignOut = useCallback(() => {
    queryClient.setQueryData(['user-profile'], null);
    localStorage.removeItem('token');
    queryClient.removeQueries();
    navigate('/login');
  }, [navigate, queryClient]);

  return onSignOut;
};

const useUserProfile = () => useQuery(
  ['user-profile'],
  async (): Promise<IUser | null> => getUserProfile(),
);

const useUpdateProfile = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IUser>) => updateProfile(data),
    onSuccess: async ({ user }) => {
      queryClient.setQueryData(['user-profile'], user);
      if (successMessage) toast(successMessage);
    },
  });
};

export {
  useSignIn,
  useForgotPassword,
  useResetPassword,
  useSignOut,
  useUserProfile,
  useUpdateProfile,
};
