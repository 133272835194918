/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getClient,
  getClients,
  getClientsList,
  updateClient,
  addClientNote,
  deleteClientNote,
  updateClientNote,
} from '../../api/clients';
import {
  ArgsGetClients, IClient, INote,
} from '~/types/clients';

const useGetClients = (params: ArgsGetClients) => useQuery({
  queryKey: ['clients', params],
  queryFn: async (): Promise<{ clients: IClient[] } | null> => getClients(params),
  keepPreviousData: true,
});

const useGetClientsList = () => useQuery({
  queryKey: ['clients-list'],
  queryFn: async (): Promise<any | null> => getClientsList(),
  keepPreviousData: true,
});

const useGetClient = (id: string) => useQuery({
  queryKey: ['client', id],
  queryFn: async (): Promise<IClient | null> => getClient(id),
  keepPreviousData: true,
});

const useUpdateClient = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IClient>) => updateClient(data),
    onSuccess: async ({ client }) => {
      queryClient.invalidateQueries({
        queryKey: ['clients'],
        exact: false,
      });
      // queryClient.invalidateQueries({ queryKey: ['clients'] });
      queryClient.setQueryData(['client', client._id], client);
      if (successMessage) toast(successMessage);
    },
  });
};

const useAddClientNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => addClientNote(data),
    onSuccess: async ({ client }) => {
      queryClient.setQueryData(['client', client._id], client);
      if (successMessage) toast(successMessage);
    },
  });
};

const useUpdateClientNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<INote> & { documentId: string }) => updateClientNote(data),
    onSuccess: async ({ client }) => {
      queryClient.setQueryData(['client', client._id], client);
      if (successMessage) toast(successMessage);
    },
  });
};

const useDeleteClientNote = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { noteId: string, clientId: string }) => deleteClientNote(data),
    onSuccess: async ({ client }) => {
      queryClient.setQueryData(['client', client._id], client);
      if (successMessage) toast(successMessage);
    },
  });
};

export {
  useGetClient,
  useGetClients,
  useGetClientsList,
  useUpdateClient,
  useAddClientNote,
  useUpdateClientNote,
  useDeleteClientNote,
};
