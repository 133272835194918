import React, { useMemo } from 'react';
import { useForgotPassword } from '../../hooks/auth';
import getErrorAuthMessage from '../../utils/auth-helpers';
import EmailResetPwdForm from '../../components/form/EmailResetPwdForm';
import signinImage from '~/assets/images/signin-image.svg';
import styles from './forgot-password.module.scss';

const ForgotPassword = () => {
  const {
    mutate: forgotPassword, isSuccess, error,
  } = useForgotPassword();
  const errorApi: any = error;

  const submitForgot = async (data: { email: string }) => {
    try {
      await forgotPassword(data);
    } catch (err) {
      console.log(err);
    }
  };

  // const errorMessage = useMemo(() => {
  //   if (!isError) return '';
  //   if (errorApi?.response?.status === 422)
  // return 'Aucun compte ne correspond à cette adresse mail.';
  //   return 'Une erreur est survenue';
  // }, [errorApi, error]);

  const errorMessage = useMemo(() => {
    if (!error) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error);
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.forgotPassword}>
      <div className={styles.container}>
        <div className={styles.picture}>
          <img src={signinImage} />
        </div>
        <div className={styles.containerForm}>
          <h1>Bienvenue sur LVTec Devis</h1>
          <div className={styles.form}>
            <h2>Mot de passe oublié</h2>
            <EmailResetPwdForm
              submit={submitForgot}
              signinLink="/login"
              succeedMessage={isSuccess ? 'Un lien permettant de réinitialiser votre mot de passe vous a été envoyé' : ''}
              errorMessage={errorMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
